import React from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css"
//Explore more Monday React Components here: https://style.monday.com/
import { Dropdown, Loader, Button, TextField, Flex, Divider,AttentionBox, LinearProgressBar, RadioButton } from "monday-ui-react-core";
import { SubitemsTable } from "./Subitems";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Intercom from '@intercom/messenger-js-sdk';

const Backend = require('./Backend');
const Config = require('./Config');

const monday = mondaySdk();

class App extends React.Component {
  constructor(props) {
    super(props);

    Intercom({
      app_id: 'efnew8bu',
      alignment: 'left',
      horizontal_padding: 100,
      vertical_padding: 40,
    });

    // Default state
    this.state = {
      context: {},
      loading: true,
      applyingTemplate: false,
      applyProgress: 0,
      updatingTemplate: false,
      creatingColuns: false,
      newTemplate: false,
      subitems: [],
      columns: [],
      newTemplateSaving: false,
      newTemplateName: '',
      newTemplateNameError: false,
      templates: [],
      selectedTemplate: null,
      sessionToken: null,
      noBoardError: false,
      newTemplateAccessLevel: 'user_level',
      updateTemplateMetadata: false,
      updateMetadataSaving: false,
      reorderItems: false,
      reorderItemsSaving: false,
      removedColumns: {},
    };

    monday.get('sessionToken').then((res) => {
      this.setState({ sessionToken: res.data });
      Backend.getTemplates(res.data).then((templates) => {
        this.setState({ loading: false, templates: templates });
      });
    });
  }

  componentDidMount() {
    monday.listen('context', this.getContext);
  }

  getContext = (res) => {
    const context = res.data;
    if (context.theme !== 'light') {
      document.body.classList.add('dark-app-theme');
    } else {
      document.body.classList.remove('dark-app-theme');
    }
    this.setState({ context });
  };

  getSubitemsBoard = async (itemID) => {
    let res = await monday.api(
      `query { items(ids:${itemID}) { name,subitems {id,name,column_values {id,value,text,type},board{id,columns{id,title,type}}} }}`,
      { apiVersion: '2023-10' }
    );
    //console.log("items",res);
    if (
      res.data.items[0].subitems == null ||
      res.data.items[0].subitems.length === 0
    ) {
      // There are no subitems under this item - create a new dummy subitem to get the board
      // It's possible to query all the boards and get the subitems board
      // However, in certain cases, subitems board is not created until at least one subitem is created
      try {
        let subitem = await monday.api(
          `mutation {create_subitem (parent_item_id: ${itemID}, item_name: "-") {id,board{id,columns{id,title,type}}}}`,
          { apiVersion: '2023-10' }
        );
        // delete the dummy subitem
        monday.api(
          `mutation {delete_item (item_id: ${subitem.data.create_subitem.id}) {id}}`,
          { apiVersion: '2023-10' }
        );
        return subitem.data.create_subitem.board;
      } catch (e) {
        this.setState({ noBoardError: true });
        return null;
      }
    } else {
      return res.data.items[0].subitems[0].board;
    }
  };

  createNewTemplate = () => {
    this.setState({ loading: true });
    const itemID = this.state.context.itemId;
    //const boardID = this.state.context.boardIds[0];

    this.getSubitemsBoard(itemID).then((board) => {
      if (board != null) {
        monday
          .api(
            `query { items(ids:${itemID}) { name,subitems {id,name,column_values {id,value,text,type}} }}`,
            { apiVersion: '2023-10' }
          )
          .then((res) => {
            let subitems = res.data.items[0].subitems;
            this.setState({
              newTemplateName: res.data.items[0].name,
              loading: false,
              newTemplate: true,
              subitems: subitems,
              columns: board.columns,
              newTemplateAccessLevel: 'user_level',
              removedColumns: {},
            });
          });
      }
    });
  };

  cancelNewTemplate = () => {
    this.setState({
      loading: false,
      newTemplate: false,
      subitems: [],
      columns: [],
      removedColumns: {},
      newTemplateNameError: false,
      newTemplateAccessLevel: 'user_level',
    });
  };

  saveNewTemplate = () => {
    this.setState({ newTemplateSaving: true, newTemplateNameError: false });
    if (this.state.newTemplateName.trim() === '') {
      this.setState({ newTemplateSaving: false, newTemplateNameError: true });
    }

    let removedColIds = {};

    let columns = this.state.columns.filter((col, index) => {
      if (this.state.removedColumns[index]) {
        removedColIds[col.id] = true;
      }
      return (
        Config.isSupportedColumn(col.type) &&
        this.state.removedColumns[index] !== true
      );
    });

    let subitems = this.state.subitems.map((subitem) => {
      subitem.column_values = subitem.column_values.filter((val) => {
        if (removedColIds[val.id]) return false;
        return true;
      });
      return subitem;
    });

    //console.log(subitems);

    Backend.newTemplate(
      this.state.sessionToken,
      this.state.newTemplateName,
      columns,
      subitems,
      this.state.newTemplateAccessLevel
    ).then((templates) => {
      this.setState({
        templates: templates,
        loading: false,
        newTemplate: false,
        subitems: [],
        columns: [],
        newTemplateNameError: false,
        newTemplateSaving: false,
        removedColumns: {},
      });
    });
  };

  newTemplateNameChange = (text) => {
    this.setState({ newTemplateName: text });
  };

  templateAccessLevelChange = (event) => {
    this.setState({
      newTemplateAccessLevel: event.currentTarget.value,
    });
  };

  columnSelectionChange = (index, val) => {
    let removedCols = { ...this.state.removedColumns };
    removedCols[index] = !val;
    this.setState({ removedColumns: removedCols });
  };

  renderNewTemplate = () => {
    return (
      <div>
        <TextField
          placeholder="Enter your template name"
          title="Template name"
          type="text"
          value={this.state.newTemplateName}
          onChange={this.newTemplateNameChange}
          wrapperClassName="monday-storybook-text-field_size"
          validation={
            this.state.newTemplateNameError
              ? { status: 'error', text: 'Invalid template name' }
              : {}
          }
        />
        <p />
        <div className="monday-style-radio-buttons_wrapper-column">
          <section className="label-component--wrapper">
            <label className="label-component--text">
              Template access level
            </label>
          </section>
          <RadioButton
            text="User level"
            name="radio-buttons-group-4"
            checked={this.state.newTemplateAccessLevel === 'user_level'}
            onSelect={this.templateAccessLevelChange}
            value="user_level"
          />
          <RadioButton
            text="Account level"
            name="radio-buttons-group-4"
            checked={this.state.newTemplateAccessLevel === 'account_level'}
            onSelect={this.templateAccessLevelChange}
            value="account_level"
          />
        </div>

        <p>Subitems</p>
        <SubitemsTable
          columns={this.state.columns ?? []}
          subitems={this.state.subitems ?? []}
          columnSelectionChange={this.columnSelectionChange}
        />
        <Flex justify={Flex.justify.SPACE_BETWEEN} style={{ width: '100%' }}>
          <Button kind={Button.kinds.TERTIARY} onClick={this.cancelNewTemplate}>
            Cancel
          </Button>
          <Button
            onClick={this.saveNewTemplate}
            loading={this.state.newTemplateSaving}>
            Save
          </Button>
        </Flex>
      </div>
    );
  };

  renderTemplateSelected = () => {
    let template = this.state.templates[this.state.selectedTemplate.value];
    return (
      <div>
        <Flex justify={Flex.justify.SPACE_BETWEEN} style={{ width: '100%' }}>
          <p>Subitems</p>
          <Button kind={Button.kinds.SECONDARY} onClick={this.reorderItems}>
            Reorder Items
          </Button>
        </Flex>
        <SubitemsTable
          columns={template.columns}
          subitems={template.subitems}
        />
        <Flex justify={Flex.justify.SPACE_BETWEEN} style={{ width: '100%' }}>
          <Button
            kind={Button.kinds.TERTIARY}
            color={Button.colors.NEGATIVE}
            onClick={this.deleteTemplate}>
            Delete Template
          </Button>
          <Button kind={Button.kinds.SECONDARY} onClick={this.updateTemplate}>
            Update Subitems
          </Button>
          <Button
            kind={Button.kinds.SECONDARY}
            onClick={this.updateTemplateMetadata}>
            Update Metadata
          </Button>
          <Button onClick={this.applyTemplate}>Apply Template</Button>
        </Flex>
      </div>
    );
  };

  mapColumns = async (board, template) => {
    /*
    Map template column IDs into subitems board column IDs.
    This checks board column title and the type and maps that column into the template column.
    Each column is only mapped once. 
    If a column cannot be found, it would create that column.
    */
    let idMap = {};
    let used = {};
    for (let i = 0; i < template.columns.length; i++) {
      let found = false;
      for (let k = 0; k < board.columns.length; k++) {
        if (used[board.columns[k].id]) continue;
        let type =
          Config.COLUMN_TYPE_NAME_MISMATCHES[template.columns[i].type] ??
          template.columns[i].type;
        if (
          template.columns[i].title === board.columns[k].title &&
          (template.columns[i].type === board.columns[k].type ||
            type === board.columns[k].type)
        ) {
          idMap[template.columns[i].id] = board.columns[k].id;
          used[board.columns[k].id] = true;
          found = true;
          break;
        }
      }
      if (!found) {
        this.setState({ creatingColuns: true });
        let type =
          Config.COLUMN_TYPE_NAME_MISMATCHES[template.columns[i].type] ??
          template.columns[i].type;
        // console.log(`mutation{
        //   create_column(board_id: ${board.id}, title:"${template.columns[i].title}", column_type:${type}) {id}
        // }`);
        try {
          let res = await monday.api(
            `mutation{
              create_column(board_id: ${board.id}, title:"${template.columns[i].title}", column_type:${type}) {id}
            }`,
            { apiVersion: '2023-10' }
          );
          idMap[template.columns[i].id] = res.data.create_column.id;
        } catch (e) {
          monday.execute('notice', {
            message:
              'There was an error while attemping to create ' +
              template.columns[i].title +
              ' column.',
            type: 'error',
            timeout: Config.NOTIFICATION_TIMEOUT,
          });
        }
      }
    }
    this.setState({ creatingColuns: false });
    return idMap;
  };

  applyTemplate = () => {
    this.setState({ loading: true, applyingTemplate: true, applyProgress: 0 });
    const itemID = this.state.context.itemId;

    Backend.trialStatus(this.state.sessionToken, itemID).then((data) => {
      if (data.message ?? false) {
        monday.api(
          `mutation {
            create_notification(user_id: ${this.state.context.user.id}, target_id: ${itemID}, text: "${data.message}", target_type: Project, internal: true) {
              text
            }
          }`,
          { apiVersion: '2023-10' }
        );
      }

      if (data.status ?? false) {
        let template = this.state.templates[this.state.selectedTemplate.value];

        this.getSubitemsBoard(itemID).then((board) => {
          if (board != null) {
            //console.log(board);
            this.mapColumns(board, template).then(async (idMap) => {
              //console.log(idMap);
              //let promises = [];

              for (let i = 0; i < template.subitems.length; i++) {
                this.setState({
                  applyProgress: (i / template.subitems.length) * 100,
                });
                let column_values = {};
                //console.log(template.subitems[i].column_values);
                for (
                  let k = 0;
                  k < template.subitems[i].column_values.length;
                  k++
                ) {
                  if (
                    !Config.isSupportedColumn(
                      template.subitems[i].column_values[k].type
                    )
                  ) {
                    continue;
                  }
                  let newColumnID =
                    idMap[template.subitems[i].column_values[k].id];
                  //console.log(template.subitems[i].column_values[k].type);
                  switch (template.subitems[i].column_values[k].type) {
                    case 'dropdown':
                      // Use the value string for dropdown to create the new label if needed
                      column_values[newColumnID] =
                        template.subitems[i].column_values[k].text;
                      break;
                    case 'color':
                    case 'status':
                      column_values[newColumnID] = {
                        label: template.subitems[i].column_values[k].text,
                      };
                      break;
                    case 'board-relation':
                      const val = JSON.parse(
                        template.subitems[i].column_values[k].value
                      );
                      if (val != null && val.linkedPulseIds != null) {
                        const itemIDs = val.linkedPulseIds.map(
                          (t) => t.linkedPulseId
                        );
                        //console.log(itemIDs);
                        column_values[newColumnID] = { item_ids: itemIDs };
                      }
                      break;
                    case 'pulse-id':
                    case 'pulse-log':
                    case 'pulse-updated':
                    case 'columns-battery':
                      break;
                    default:
                      column_values[newColumnID] = JSON.parse(
                        template.subitems[i].column_values[k].value
                      );
                  }
                }
                //console.log(column_values);
                let column_values_json = JSON.stringify(
                  JSON.stringify(column_values)
                );
                let name = template.subitems[i].name;
                try {
                  await monday.api(
                    `mutation {create_subitem (parent_item_id: ${itemID}, item_name: "${name.replaceAll(
                      '"',
                      '\\"'
                    )}",column_values:${column_values_json}, create_labels_if_missing:true) {id}}`,
                    { apiVersion: '2023-10' }
                  );
                } catch (e) {
                  console.log(e);
                }
              }

              this.setState({
                loading: false,
                selectedTemplate: null,
                applyingTemplate: false,
              });
              monday.execute('notice', {
                message:
                  'Template applied successfully. Refresh the page if any data is missing.',
                type: 'success', // or "error" (red), or "info" (blue)
                timeout: Config.NOTIFICATION_TIMEOUT,
              });

              Backend.templateOperation(
                this.state.sessionToken,
                'template_applied',
                template.id
              );

              /*Promise.all(promises).then((res) => {
                  this.setState({loading:false,selectedTemplate:null});
                });*/
            });
          }
        });
      } else {
        this.setState({
          loading: false,
          selectedTemplate: null,
          applyingTemplate: false,
        });
      }
    });
  };

  updateTemplate = () => {
    monday
      .execute('confirm', {
        message:
          "This action would replace your current template with the selected item's subitems. Do you want to proceed?",
        confirmButton: 'Yes, update it!',
        cancelButton: 'Cancel',
        excludeCancelButton: false,
      })
      .then((res) => {
        if (res.data.confirm) {
          this.setState({ loading: true, updatingTemplate: true });
          const itemID = this.state.context.itemId;

          this.getSubitemsBoard(itemID).then((board) => {
            monday
              .api(
                `query { items(ids:${itemID}) { name,subitems {id,name,column_values {id,value,text,type}} }}`,
                { apiVersion: '2023-10' }
              )
              .then((res) => {
                let subitems = res.data.items[0].subitems;
                let templateID =
                  this.state.templates[this.state.selectedTemplate.value].id;
                let columns = board.columns.filter((col) =>
                  Config.isSupportedColumn(col.type)
                );

                Backend.updateTemplate(
                  this.state.sessionToken,
                  templateID,
                  columns,
                  subitems
                ).then((templates) => {
                  this.setState({
                    templates: templates,
                    loading: false,
                    updatingTemplate: false,
                    newTemplate: false,
                    subitems: [],
                    columns: [],
                    newTemplateNameError: false,
                    newTemplateSaving: false,
                  });
                });
              });
          });
        }
      });
  };

  deleteTemplate = () => {
    monday
      .execute('confirm', {
        message: 'Are you sure you want to delete this template?',
        confirmButton: 'Yes, delete it!',
        cancelButton: 'Cancel',
        excludeCancelButton: false,
      })
      .then((res) => {
        if (res.data.confirm) {
          this.setState({ loading: true });

          let templateID =
            this.state.templates[this.state.selectedTemplate.value].id;

          Backend.deleteTemplate(this.state.sessionToken, templateID).then(
            (templates) => {
              this.setState({
                templates: templates,
                loading: false,
                selectedTemplate: null,
              });
            }
          );
        }
      });
  };

  templateChanged = (val) => {
    this.setState({ selectedTemplate: val });
  };

  renderUpdateTemplateMetadata = () => {
    return (
      <div>
        <TextField
          placeholder="Enter your template name"
          title="Template name"
          type="text"
          value={this.state.newTemplateName}
          onChange={this.newTemplateNameChange}
          wrapperClassName="monday-storybook-text-field_size"
          validation={
            this.state.newTemplateNameError
              ? { status: 'error', text: 'Invalid template name' }
              : {}
          }
        />
        <p />
        <div className="monday-style-radio-buttons_wrapper-column">
          <section className="label-component--wrapper">
            <label className="label-component--text">
              Template access level
            </label>
          </section>
          <RadioButton
            text="User level"
            name="radio-buttons-group-4"
            checked={this.state.newTemplateAccessLevel === 'user_level'}
            onSelect={this.templateAccessLevelChange}
            value="user_level"
          />
          <RadioButton
            text="Account level"
            name="radio-buttons-group-4"
            checked={this.state.newTemplateAccessLevel === 'account_level'}
            onSelect={this.templateAccessLevelChange}
            value="account_level"
          />
        </div>
        <p />
        <Flex justify={Flex.justify.SPACE_BETWEEN} style={{ width: '100%' }}>
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={this.cancelTemplateMetadata}>
            Cancel
          </Button>
          <Button
            onClick={this.saveTemplateMetadata}
            loading={this.state.updateMetadataSaving}>
            Save
          </Button>
        </Flex>
      </div>
    );
  };

  cancelTemplateMetadata = () => {
    this.setState({ loading: false, updateTemplateMetadata: false });
  };

  updateTemplateMetadata = () => {
    let template = this.state.templates[this.state.selectedTemplate.value];
    this.setState({
      updateTemplateMetadata: true,
      newTemplateName: template.name,
      newTemplateAccessLevel: template.access_level,
      newTemplateNameError: false,
    });
  };

  saveTemplateMetadata = () => {
    this.setState({ updateMetadataSaving: true, newTemplateNameError: false });
    if (this.state.newTemplateName.trim() === '') {
      this.setState({
        updateMetadataSaving: false,
        newTemplateNameError: true,
      });
    }

    let templateID = this.state.templates[this.state.selectedTemplate.value].id;

    Backend.updateTemplateMetadata(
      this.state.sessionToken,
      templateID,
      this.state.newTemplateName,
      this.state.newTemplateAccessLevel
    ).then((templates) => {
      this.setState({
        templates: templates,
        loading: false,
        updateTemplateMetadata: false,
        subitems: [],
        columns: [],
        newTemplateNameError: false,
        updateMetadataSaving: false,
        selectedTemplate: null,
      });
    });
  };

  reorderItems = () => {
    let template = this.state.templates[this.state.selectedTemplate.value];
    this.setState({ reorderItems: true, originalOrder: template.subitems });
  };

  cancelReorderItems = () => {
    let templates = [...this.state.templates];
    if (this.state.originalOrder ?? null) {
      templates[this.state.selectedTemplate.value].subitems =
        this.state.originalOrder;
    }
    this.setState({
      loading: false,
      reorderItems: false,
      originalOrder: null,
      templates: templates,
    });
  };

  onDragEnd = (e) => {
    //console.log(e);
    if (!e.destination) {
      return;
    }
    let templates = [...this.state.templates];
    let template = templates[this.state.selectedTemplate.value];
    const sorted = this.reorder(
      template.subitems,
      e.source.index,
      e.destination.index
    );
    templates[this.state.selectedTemplate.value].subitems = sorted;
    this.setState({ templates: templates });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  saveTemplateReorder = () => {
    this.setState({ reorderItemsSaving: true });
    let template = this.state.templates[this.state.selectedTemplate.value];

    Backend.reorderTemplate(
      this.state.sessionToken,
      template.id,
      template.subitems
    ).then((templates) => {
      this.setState({
        templates: templates,
        reorderItemsSaving: false,
        loading: false,
        reorderItems: false,
        originalOrder: null,
      });
    });
  };

  renderReorderItems = () => {
    let template = this.state.templates[this.state.selectedTemplate.value];

    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {template.subitems.map((item, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            padding: '10px',
                            marginBottom: '8px',
                            border: '1px solid #ccc',
                            ...provided.draggableProps.style,
                          }}>
                          {item.name}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Flex justify={Flex.justify.SPACE_BETWEEN} style={{ width: '100%' }}>
          <Button
            kind={Button.kinds.TERTIARY}
            onClick={this.cancelReorderItems}>
            Cancel
          </Button>
          <Button
            onClick={this.saveTemplateReorder}
            loading={this.state.reorderItemsSaving}>
            Save
          </Button>
        </Flex>
      </div>
    );
  };

  render() {
    if (this.state.context.user && this.state.context.user.isViewOnly) {
      return (
        <div className="centered">
          <AttentionBox
            title="Unauthorized"
            text="As a viewer, you are unable to use the Subitem Pro Templates app."
            type={AttentionBox.types.DANGER}
            className="monday-style-attention-box_box"
          />
        </div>
      );
    }
    if (this.state.noBoardError) {
      return (
        <div className="centered">
          <AttentionBox
            title="No subitems board"
            text="This board has not been initialized for subitems. Create a subitem via the UI first to proceed."
            type={AttentionBox.types.DANGER}
            className="monday-style-attention-box_box"
          />
        </div>
      );
    }

    if (this.state.loading) {
      if (this.state.applyingTemplate) {
        return (
          <div>
            <LinearProgressBar
              className="linear-progress-bar_small-wrapper"
              indicateProgress
              value={this.state.applyProgress}
              size={LinearProgressBar.sizes.LARGE}
            />
            <p />
            {this.state.creatingColuns && (
              <div className="centered">Creating missing columns.</div>
            )}
            <div className="centered">
              Applying your template, please hang in there.
            </div>
          </div>
        );
      }
      return (
        <div>
          <div className="centered">
            <Loader size={40} />
          </div>
          {this.state.updatingTemplate && (
            <div className="centered">Updating template.</div>
          )}
        </div>
      );
    }

    if (this.state.newTemplate) {
      return this.renderNewTemplate();
    }

    if (this.state.updateTemplateMetadata) {
      return this.renderUpdateTemplateMetadata();
    }

    if (this.state.reorderItems) {
      return this.renderReorderItems();
    }

    return (
      <div>
        <Dropdown
          className="dropdown-stories-styles_spacing"
          onChange={this.templateChanged}
          options={this.state.templates.map((template, index) => {
            return { label: template.name, value: index };
          })}
          placeholder="Select a template"
          value={this.state.selectedTemplate}
        />
        <Divider />
        {this.state.selectedTemplate !== null ? (
          this.renderTemplateSelected()
        ) : (
          <p>
            <Button onClick={this.createNewTemplate}>
              Create a new template
            </Button>
          </p>
        )}
      </div>
    );
  }
}

export default App;
