import React from "react";
import { Checkbox } from "monday-ui-react-core";

const Config = require('./Config');

export class SubitemsTable extends React.Component {
    render() {
        return <table>
        <thead key="thead">
          <tr key="heading">
          {this.props.columns.map((col,index) => {
            return<td key={index} className={Config.isSupportedColumn(col.type)?"":"danger"}>
              {col.title}
              {this.props.columnSelectionChange && index !== 0 && 
                <Checkbox
                  defaultChecked
                  onChange={(event) => {this.props.columnSelectionChange(index,event.currentTarget.checked)}}
                />
                }
            </td>;
          })}
          </tr>
        </thead>
        <tbody key="tobdy">
          {this.props.subitems.map(item => {
            return <tr key={item.id}>
              {this.props.columns.map((col,index) => {
                return <td key={index}>{col.id === "name" ? item.name : item.column_values.filter((x) => x.id===col.id).map((x) => x.text)}</td>;
              })}
            </tr>
          })}
        </tbody>
      </table>
    }
}