const getTemplates = async (sessionToken) => {
  let res = await fetch("https://spt.b-lab.app/templates/" + sessionToken);
  return await res.json();
};

const newTemplate = async (
  sessionToken,
  name,
  columns,
  subitems,
  accessLevel
) => {
  const data = new FormData();
  data.append("name", name);
  data.append("columns", JSON.stringify(columns));
  data.append("subitems", JSON.stringify(subitems));
  data.append("access_level", accessLevel);
  let res = await fetch("https://spt.b-lab.app/templates/new/" + sessionToken, {
    method: "POST",
    body: data,
  });
  return await res.json();
};

const updateTemplateMetadata = async (sessionToken, id, name, accessLevel) => {
  const data = new FormData();
  data.append("id", id);
  data.append("name", name);
  data.append("access_level", accessLevel);
  let res = await fetch(
    "https://spt.b-lab.app/templates/update/metadata/" + sessionToken,
    {
      method: "POST",
      body: data,
    }
  );
  return await res.json();
};

const updateTemplate = async (sessionToken, id, columns, subitems) => {
  const data = new FormData();
  data.append("id", id);
  data.append("columns", JSON.stringify(columns));
  data.append("subitems", JSON.stringify(subitems));
  let res = await fetch(
    "https://spt.b-lab.app/templates/update/" + sessionToken,
    {
      method: "POST",
      body: data,
    }
  );
  return await res.json();
};

const reorderTemplate = async (sessionToken, id, subitems) => {
  const data = new FormData();
  data.append("id", id);
  data.append("subitems", JSON.stringify(subitems));
  let res = await fetch(
    "https://spt.b-lab.app/templates/reorder/" + sessionToken,
    {
      method: "POST",
      body: data,
    }
  );
  return await res.json();
};

const deleteTemplate = async (sessionToken, id) => {
  const data = new FormData();
  data.append("id", id);
  let res = await fetch(
    "https://spt.b-lab.app/templates/delete/" + sessionToken,
    {
      method: "POST",
      body: data,
    }
  );
  return await res.json();
};

const templateOperation = async (sessionToken, operation, template_id) => {
  const data = new FormData();
  data.append("operation", operation);
  data.append("template_id", template_id);
  let res = await fetch(
    "https://spt.b-lab.app/templates/operation/" + sessionToken,
    {
      method: "POST",
      body: data,
    }
  );
  return await res.json();
};

const trialStatus = async (sessionToken,itemId) => {
    let res = await fetch("/check_purchase/" + itemId + "/" + sessionToken);
    return await res.json();
}


export {
    getTemplates,
    newTemplate,
    updateTemplate,
    deleteTemplate,
    updateTemplateMetadata,
    templateOperation,
    trialStatus,
    reorderTemplate
};